@tailwind base;
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: grey lightgrey;
}
/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}
*::-webkit-scrollbar-track {
  background-color: rgb(189, 189, 189);
}
*::-webkit-scrollbar-thumb {
  background-color: rgb(219, 219, 219);
}
/* datepicker global styles  */
.datepicker input {
  width: 100%;
  height: 100%;
  border: #1cc695 2px solid;
  border-radius: 8px;
  outline: none;
  font-size: 1.2rem;
  padding: 0.5rem;
  background-color: transparent;
  color: grey;
  cursor: pointer;
}
.datepicker input::placeholder {
  color: grey;
  font-size: 1rem;
}

.datepicker input:focus {
  border: #1cc695 2px solid;
  border-radius: 8px;
  outline: none;
  font-size: 1.2rem;
  padding: 0.5rem;
  background-color: transparent;
  color: grey;
  cursor: pointer;
}
.datepicker .react-datepicker__triangle {
  display: none;
}
.datepicker .react-datepicker__header {
  background-color: #1cc695;
}
.datepicker .react-datepicker__current-month {
  color: white;
}
.datepicker .react-datepicker__day-name {
  color: white;
}
.datepicker .react-datepicker__day--selected {
  background-color: #1cc695;
  color: white;
}
.datepicker .react-datepicker__day--in-range {
  background-color: #1cc695;
  color: white;
}
.datepicker .react-datepicker__navigation {
  height: 50px;
}
.datepicker
  .react-datepicker__navigation-icon.react-datepicker__navigation-icon--previous::before {
  border-color: white;
}
.datepicker .react-datepicker__navigation-icon::before {
  border-color: white;
}

@tailwind components;
@tailwind utilities;
